<template>
  <v-row align="center" justify="center">
    <v-col class="text-center" cols="12" md="4">
      <v-card
        class="mx-auto card pt-5"
        max-width="344"
        height="250"
        data-aos="flip-left"
      >
        <v-card-text>
          <div class="text-h4 goldColor">
            <v-icon large color="#d4b506">mdi-lock</v-icon> Legal
          </div>
          <div class="textWhite">
            Our Operations and conducts are all Legal as we've obtained correct
            licenses and dully certified by the Financial Conduct Authority
            (FCA) in the jurisdiction of Great Britain.
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="text-center" cols="12" md="4">
      <v-card
        class="mx-auto card pt-5"
        max-width="344"
        height="250"
        data-aos="flip-right"
      >
        <v-card-text>
          <div class="text-h4 goldColor">
            <v-icon large color="#d4b506">mdi-lock</v-icon> High reliability
          </div>
          <div class="textWhite">
            LXGMINERS INC is trusted by a huge number of amazing investors. We
            are working hard constantly to improve the level of security and
            eliminate every possible risk..
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="text-center" cols="12" md="4">
      <v-card
        class="mx-auto card pt-5"
        max-width="344"
        height="250"
        data-aos="flip-left"
      >
        <v-card-text>
          <div class="text-h4 goldColor">
            <v-icon large color="#d4b506">mdi-lock</v-icon> Withdrawals
          </div>
          <div class="textWhite">
            We offer one of the fastest withdrawals, as withdrawals are
            processed within 10 minutes of request but sometimes take longer to
            post due to the Blockchain network congestion. Be Patient your
            withdrawal is on the way!
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="text-center" cols="12" md="4">
      <v-card
        class="mx-auto card pt-5"
        max-width="344"
        height="250"
        data-aos="flip-right"
      >
        <v-card-text>
          <div class="text-h4 goldColor">
            <v-icon large color="#d4b506">mdi-lock</v-icon> 24/7 Support
          </div>
          <div class="textWhite">
            We provide 24 hour support to visitors and investors through the
            Live Chat althrough the week. Our agents are always on standby ready
            to assist and attend to your needs. You can locate the Live Chat
            button at the bottom right corner of the platform.
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  created() {
    AOS.init({
      once: true,
      offset: 300, // offset (in px) from the original trigger point
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
    });
  },
};
</script>
<style scoped>
.card {
  background-color: rgb(5, 5, 5, 0.8) !important;
}
.textWhite {
  color: white !important;
}
.goldColor {
  color: #d4b506 !important;
}
</style>