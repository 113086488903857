<template>
  <div class="main">
    <div class="page-bg"></div>

    <div class="animation-wrapper">
      <div class="particle particle-1"></div>
      <div class="particle particle-2"></div>
      <div class="particle particle-3"></div>
      <div class="particle particle-4"></div>
    </div>

    <div class="section-1 pt-7">
      <h1 class="display-2 font-weight-bold mb-4 textWhite text-center">
        <vue-typed-js
          :strings="['START LXGMINERS TODAY']"
          :backDelay="3000"
          :loop="true"
        >
          <span class="typing"></span>
        </vue-typed-js>
      </h1>
      <h4 class="subheading text-center">
        It's super simple - Your mining rigs are already set up and running. As
        soon as you've set up your account,<br />
        you can start to earn your first coins from our Bitcoin pool mining
        service.
      </h4>
    </div>
    <div class="text-center mt-5 mb-5 pb-5">
      <v-btn
        @click="navigate('signup')"
        class="ma-3"
        x-large
        color="#d4b506"
        light
      >
        Signup
      </v-btn>
      <v-btn
        @click="navigate('login')"
        class="ma-3"
        x-large
        color="#d4b506"
        light
      >
        Login
      </v-btn>
    </div>
    <div class="mt-5">
      <v-img
        max-height="650"
        width="100%"
        style="background-color: black"
        src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcBg.jpeg?alt=media&token=89e802e6-37b5-4fdc-8560-6b4f23aa938f"
      >
        <v-row data-aos="fade-up-left" class="fill-height ma-0" align="center">
          <v-col cols="12" md="6">
            <h1 class="display-2 font-weight-light textWhite">
              Invest, Grow, Earn
            </h1>
            <h1 class="display-2 font-weight-light textWhite mt-2">
              in Our Stable
            </h1>
            <h1 class="display-2 font-weight-light textWhite mt-2">Platform</h1>
            <h1 class="display-2 font-weight-bold goldColor mt-2">
              and Build Your Future
            </h1>

            <h5 class="font-weight-normal white--text mt-9 about">
              An Industry Leader with Fully Automated Mining Rigs, Providing the
              Most Convenient Platform for Investors to get Share-profits from
              Well Organized Pool Mines Built for Profiting. Not Only do We
              Guarantee the Fastest and Most Exciting returns on investments, We
              also Guarantee Security on Every Investment.
            </h5>
            <v-btn
              @click="navigate('signup')"
              class="ma-3 mt-8"
              x-large
              color="#d4b506"
              light
            >
              Get Started
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
    </div>
    <v-container>
      <div
        style="
          height: 374px;
          background-color: #1d2330;
          overflow: hidden;
          box-sizing: border-box;
          border: 1px solid #282e3b;
          border-radius: 4px;
          text-align: right;
          line-height: 14px;
          font-size: 12px;
          font-feature-settings: normal;
          text-size-adjust: 100%;
          box-shadow: inset 0 -20px 0 0 #262b38;
          padding: 0px;
          margin: 0px;
          width: 100%;
        "
      >
        <div style="height: 354px; padding: 0px; margin: 0px; width: 100%">
          <iframe
            src="https://widget.coinlib.io/widget?type=full_v2&theme=dark&cnt=5&pref_coin_id=1505&graph=yes"
            width="100%"
            height="350px"
            scrolling="auto"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            border="0"
            style="border: 0; margin: 0; padding: 0"
          ></iframe>
        </div>
        <div
          style="
            color: #626b7f;
            line-height: 14px;
            font-weight: 400;
            font-size: 11px;
            box-sizing: border-box;
            padding: 2px 6px;
            width: 100%;
            font-family: Verdana, Tahoma, Arial, sans-serif;
          "
        >
          <a
            href="https://coinlib.io"
            target="_blank"
            style="
              font-weight: 500;
              color: #626b7f;
              text-decoration: none;
              font-size: 11px;
            "
            >Cryptocurrency Prices</a
          >&nbsp;by Coinlib
        </div>
      </div>
    </v-container>
    <div class="mb-7 mt-10">
      <Card />
    </div>
    <div class="mt-5">
      <v-img
        min-height="300"
        width="100%"
        src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcking-removebg-preview.png?alt=media&token=e77b3e42-6d78-4ffd-8c6d-ce3150d0e8a6"
      >
        <div class="">
          <div class="section-2 pa-5">
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="12" md="6">
                <h1 class="font-weight-bold mb-4 textWhite">
                  Why Choose <span class="goldColor">LXGMINERS</span>
                </h1>
                <h5 class="subheading font-weight-normal textWhite">
                  We created an opportunity for investors to benefit from the
                  most reliable and convenient way to get share profits in our
                  well organized pool mines without any technical knowledge and
                  expertise, while offering high-quality service..
                </h5>
              </v-col>
            </v-row>
          </div>
          <CardTwo />
        </div>
      </v-img>
    </div>
    <div class="ma-5">
      <h1 class="text-h4 text-center font-weight-bold mb-4 textWhite">
        <span class="goldColor">LXGMINERS</span> DAILY STATS
      </h1>
      <CardThree />
    </div>
    <div class="section-2 text-center pt-5">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12" mb="6">
          <h1 class="text-h3 font-weight-bold mb-4 textWhite">
            Get Started Today With Us
          </h1>
          <h5 class="subheading font-weight-normal textWhite">
            This is a Revolutionary Platform! Invest and build a Future in this
            stable platform. Not only do we guarantee the fastest and most
            exciting returns on your investments, we also guarantee the security
            of every investment
          </h5>
          <div class="text-center mt-5 mb-5 pb-5">
            <v-btn
              @click="navigate('signup')"
              class="ma-3"
              x-large
              color="#d4b506"
              light
            >
              Get Started
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="section-2 pa-5" data-aos="fade-zoom-in">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12" md="6">
          <h1 class="display-1 font-weight-bold mb-4 textWhite">
            Payment We <span class="goldColor"> Accept </span>
          </h1>
          <h5 class="subheading font-weight-normal textWhite">
            We accept all major cryptocurrencies and fiat payment methods to
            make your investment process easier with our platform.
          </h5>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12" sm="1"></v-col>
        <v-col class="text-center" cols="12" md="3" sm="12">
          <v-img
            height="150"
            width="150"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btc-removebg-preview.png?alt=media&token=9195e22f-019c-4625-9c0a-254979e568f3"
          ></v-img>
        </v-col>

        <v-col class="text-center" cols="12" md="3" sm="12">
          <v-img
            height="120"
            width="120"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/ETH-logo-removebg-preview.png?alt=media&token=29d86379-4b93-487e-ba76-76a70fac0ce3"
          >
            <div class="fill-height bottom-gradient"></div>
          </v-img>
        </v-col>

        <v-col class="text-center" cols="12" md="3" sm="12">
          <v-img
            height="150"
            width="150"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/tether-removebg-preview.png?alt=media&token=5df2fcbe-cc62-454d-aadf-3afebfddb52e"
          >
            <div class="fill-height repeating-gradient"></div>
          </v-img>
        </v-col>
        <v-col class="text-center" cols="12" sm="1"></v-col>
      </v-row>
    </div>
    <div class="pa-5 ma-10 section-2">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12" md="6">
          <h1 class="display-1 font-weight-bold mb-4 textWhite">
            <span class="goldColor">Subscribe</span> Our Newsletter
          </h1>
        </v-col>
        <v-col class="text-center" cols="12" md="6">
          <v-text-field v-model="email" label="Email" filled dark>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-btn
                  light
                  color="#d4b506"
                  @click="NewsLetter()"
                  :disabled="loading"
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-fade-transition>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-footer color="black" class="footer">
      <v-row justify="center" no-gutters class="pb-7">
        <v-col class="py-4 text-center white--text" cols="12">
          <div class="text-center">
            <h1 class="white--text">
              <v-icon large color="#d4b506">mdi-bitcoin</v-icon>
              LXGMiners
            </h1>
          </div>
        </v-col>
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn>
        <v-col class="py-4 white--text" cols="12">
          © {{ new Date().getFullYear() }} —
          <strong>
            <span class="goldColor">LXGMINERS INC.</span>
            All rights reserved
          </strong>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Card from "../components/HelloWorld.vue";
import CardTwo from "../components/CardTwo.vue";
import CardThree from "../components/CardThree.vue";
export default {
  components: {
    Card,
    CardTwo,
    CardThree,
  },
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    links: ["Home", "Privacy & Policy", "Terms & Conditions"],
    email: "",
  }),
  created() {
    AOS.init({
      once: true,
      offset: 300, // offset (in px) from the original trigger point
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
    });
    if (this.page.params) {
      localStorage.setItem("referralCode", this.page.params.userName);
    }
  },
  computed: {
    page() {
      return this.$route;
    },
  },
  methods: {
    navigate(payload) {
      this.$router.push({ name: payload });
    },
    NewsLetter() {
      if (this.email == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        email: this.email,
      };
      this.$store.dispatch("NewsLetter", payload);
      this.email = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  flex: 1;
  background-color: black;
  height: 100vh;
}
.footer {
  border-top: 1px solid #d4b506 !important;
}
.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.about {
  line-height: 1.8;
  background-color: black;
  padding: 5px;
}
.section-2 {
  background-color: rgb(5, 5, 5, 0.8) !important;
  margin: 5em;
}
.goldColor {
  color: #d4b506 !important;
}
.textWhite {
  color: white;
}
/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

/* Styles */
html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  overflow: hidden;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

.page-wrapper {
  text-align: center;
  color: #fff;
  z-index: 2;
}

h4 {
  color: #fff;
  font-family: Monoton;
  -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
  -moz-animation: neon1 1.5s ease-in-out infinite alternate;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #ff1177;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
</style>
